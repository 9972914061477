import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from './Insuranceapplication.module.scss';
import ContentCard from "../../Shared/ContentCard/ContentCard";
import CategorySelector from "../../Shared/CategorySelector/CategorySelector";
import { InputGroup } from "@sb1/ffe-form-react";
import { Grid, GridCol, GridRow } from "@sb1/ffe-grid-react";
import { ActionButton, SecondaryButton, TertiaryButton } from "@sb1/ffe-buttons-react";
import { useAuth } from "../../../Auth";
import Spinner from "@sb1/ffe-spinner-react";
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';

import { Insuranceapplication as InsuranceapplicationModel } from "../../../model/Insuranceapplication";
import InsuranceObjectCard from "./Partials/InsuranceObjectCard/InsuranceObjectCard";
import general from "../../../functions/general";
import InsuranceCustomerCard from "./Partials/InsuranceCustomerCard/InsuranceCustomerCard";
import InsurancePricingCard from "./Partials/InsurancePricingCard/InsurancePricingCard";
import InputWithUsability from "../../Shared/InputWithUsability/InputWithUsability";
import { useAxios } from "../../../AxiosHandler";
import moment from "moment";
import { formatPhoneNumber, formatRegistrationNumber } from "../../../functions/dataSanitation";
import { config } from "../../../functions/config";

function Insuranceapplication() {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;
    const navigate = useNavigate();
    
    const [problems, setProblems] = useState<Array<string>>([]);
    const [sendingInsurance, setSendingInsurance] = useState<boolean>(false);

    const [errorDetails, setErrorDetails] = useState<any>(undefined);
    const [receipt, setReceipt] = useState<any>(undefined);

    const [foundVehicle, setFoundVehicle] = useState<string>("");

    function clearForm() {
        auth.setCurrentInsuranceapplication(new InsuranceapplicationModel());
        setFoundVehicle("");
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    function sendInsuranceapplication(state: string | undefined = undefined, update: boolean = false) {
        setErrorDetails(undefined);
        setSendingInsurance(true);

        if(auth.currentInsuranceapplication?.category == "CAMPINGVOGN") {
            sendInsuranceapplicationForCampingvogn(update);
            return;
        }

        if (!state || state != "tilbud") {
            toast.info("Sender forsikringsforespørsel ...", { autoClose: false });
        } else {
            toast.info((update ? "Oppdaterer" : "Lagrer") + " tilbud ...", { autoClose: false });
        }

        let currentMerkeNr = auth.currentInsuranceapplication?.regnumber == undefined || auth.currentInsuranceapplication?.regnumber == "" ? auth.currentInsuranceapplication?.objectmake : undefined;
        if(auth.currentInsuranceapplication?.category == "BOBIL"){
            currentMerkeNr = auth.currentInsuranceapplication?.objectmakeCode;
        }

        axios.single({
            url: (!state && state != "tilbud") ? "/api/fremtind/bil/kjop" : "/api/fremtind/bil/tilbud",
            method: update ? "PUT" : "POST",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"]),
                "X-Request-Id": general.generateXRequestId(),
            },
            data: {
                "ID": update ? auth.currentInsuranceapplication?.ID : undefined,

                "sequence" : update ? auth.currentInsuranceapplication?.sequence : undefined,
                "caseSequence" : undefined,
                "userSequence" : auth.user?.userdata?.Sequence,
                "dealerSequence" : auth.selectedDealer?.Sequence,
                "customerSequence" : auth.currentInsuranceapplication?.customerSequence,
                
                "kundeNummer": update ? auth.currentInsuranceapplication?.kundeNummer : undefined,
                "avtaleNummer": update ? auth.currentInsuranceapplication?.avtaleNummer : undefined,
                "avtaleVersjon": update ? auth.currentInsuranceapplication?.avtaleVersjon : undefined,

                "produkt": auth.currentInsuranceapplication?.category,
                "eierFodselsnummer": auth.currentInsuranceapplication?.customerSocialSecurityNumber,
                //"eierOrgNummer":
                "regnr": auth.currentInsuranceapplication?.regnumber != "" ? formatRegistrationNumber(auth.currentInsuranceapplication?.regnumber, true) : undefined,
                "merkenr": currentMerkeNr,
                "modellnr": auth.currentInsuranceapplication?.regnumber == undefined || auth.currentInsuranceapplication?.regnumber == "" ? auth.currentInsuranceapplication?.objectmodel : undefined,
                "variantnr": auth.currentInsuranceapplication?.regnumber == undefined || auth.currentInsuranceapplication?.regnumber == "" ? auth.currentInsuranceapplication?.objecttype : undefined,
                "aarsModell": auth.currentInsuranceapplication?.regnumber == undefined || auth.currentInsuranceapplication?.regnumber == "" ? auth.currentInsuranceapplication?.objectyear : undefined,
                "chassisNummer": auth.currentInsuranceapplication?.regnumber == undefined || auth.currentInsuranceapplication?.regnumber == "" ? auth.currentInsuranceapplication?.chassisnumber : undefined,
                "registreringsAar": auth.currentInsuranceapplication?.objectyear,
                "kjorelengdeAar": auth.currentInsuranceapplication?.insuranceAnnualKilometers,
                "bonus": auth.currentInsuranceapplication?.insuranceBonus,
                "bonusFraSelskap": Number(auth.currentInsuranceapplication?.insuranceBonus || 0) > 60 ?
                    auth.currentInsuranceapplication?.insuranceBonusSource
                    : undefined,
                "bonusFraRegnr": Number(auth.currentInsuranceapplication?.insuranceBonus || 0) > 60 ?
                    formatRegistrationNumber(auth.currentInsuranceapplication?.insuranceBonusSourceRegnr, true)
                    : undefined,
                //"bonusFraAvtalenr": "",
                //"eksisterendeSelskapsKode": "",
                //"bonusTransferType": "",
                "markedsKilde": "625",
                "belaanesMedPant": auth.currentInsuranceapplication?.loanLeasing == "loan",
                "yngreSjaafor": auth.currentInsuranceapplication?.insuranceLowAge,
                "leasing": auth.currentInsuranceapplication?.loanLeasing == "leasing",
                "kilometerstand": isNaN(auth.currentInsuranceapplication?.mileage) ? 0 : Number(auth.currentInsuranceapplication?.mileage),
                "eierEpost": auth.currentInsuranceapplication?.customerEmail || "",
                "eierMobil": formatPhoneNumber(auth.currentInsuranceapplication?.customerPhone || ""),
                "dekningsTypeKode": auth.currentInsuranceapplication?.selectedInsurance?.dekningsTypeKode,
                "egenAndelsKode": auth.currentInsuranceapplication?.selectedDeductible,
                //"bilforhandlerKundenummer": "",
                "bilforhandlerOrgnummer": auth.selectedDealer?.OrgNumber,
                "salgsAgentId": auth.user?.userdata?.Username || "",
                "bilforhandlerId": (config.region == "ØST" ? "sfo-" : (config.region == "NORD" ? "sfnn-" : "")) + auth.selectedDealer?.Sequence?.toString() || "",
                //"startDato": "",
                //"sendKmStandSms": true,
                "panthaverKundenummer":
                    auth.currentInsuranceapplication?.loanLeasing == "loan" ||
                    auth.currentInsuranceapplication?.loanLeasing == "leasing" ?
                        auth.currentInsuranceapplication?.loanLeasingCompany
                    : undefined,
                //"panthaverOrgnummer": "",
                "ekundeSamtykke": true,
                /* "tilleggsDekninger": [ // TODO: Readd this when send insurance is available for BM. This is not in use for PM.
                    {
                        "dekningsTypeKode": auth.currentInsuranceapplication?.selectedInsurance?.dekningsTypeKode,
                //        "sum": "string",
                        "egenAndelsKode": auth.currentInsuranceapplication?.selectedDeductible
                    }
                ], */
                // "kontaktPerson": {
                //     "forNavn": "string",
                //     "etterNavn":  "string",
                //     "mobil": "string",
                //     "epost": "string"
                // },
            }
        }).then((res: any) => {
            toast.dismiss();
            setSendingInsurance(false);
            auth.setCurrentInsuranceapplication({
                ...auth.currentInsuranceapplication || new InsuranceapplicationModel(),
                avtaleNummer: res.data.avtaleNummer,
                avtaleVersjon: res.data.avtaleVersjon,
                kundeNummer: res.data.kundeNummer,
                messageId: res.data.messageId,
                CustomerSequence: res.data.CustomerSequence,
                DealerSequence: res.data.DealerSequence,
                ID: res.data.ID,
            });
            
            if(res.data?.Message != undefined){
                toast.error(res.data?.Message);
            } else {
                toast.success(state != "tilbud" ? "Forsikringsforespørsel sendt!" : "Tilbud lagret!");
                navigate("/insuranceoverview?insuranceid=" + res.data.ID);
            }
        }).catch((err: any) => {
            toast.dismiss();
            setSendingInsurance(false);
            console.log(err);
            if (!state || state != "tilbud") {
                toast.error("Kunne ikke sende forsikringsforespørsel!");
            } else {
                toast.error("Kunne ikke lagre tilbud!");
            }
            toast.error("Forsikringen kan ikke tegnes. Kontakt forhandlerstøtte tlf 21 02 80 22");
        });
    }

    function sendInsuranceapplicationForCampingvogn(update: boolean = false) {
        toast.info("Sender forsikringsforespørsel ...", { autoClose: false });

        axios.single({
            url: "/api/fremtind/bil/campingvogn",
            method: "POST",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"]),
                "X-Request-Id": general.generateXRequestId(),
            },
            data: {
                "produkt": auth.currentInsuranceapplication?.category,
                //"eierFodselsnummer": auth.currentInsuranceapplication?.customerSocialSecurityNumber, // Erstattet med kundeFodselsnummer
                "kundeFodselsnummer": auth.currentInsuranceapplication?.customerSocialSecurityNumber, //TODO: Finne riktig verdi
                "eierOrganisasjonsnummer": undefined, //TODO: Finne riktig verdi
                "regnr": auth.currentInsuranceapplication?.regnumber != "" ? formatRegistrationNumber(auth.currentInsuranceapplication?.regnumber, true) : undefined,
                "merkekode": auth.currentInsuranceapplication?.objectmakeCode,
                "modellnr": auth.currentInsuranceapplication?.regnumber == undefined || auth.currentInsuranceapplication?.regnumber == "" ? auth.currentInsuranceapplication?.objectmodel : undefined,
                "variantnr": auth.currentInsuranceapplication?.regnumber == undefined || auth.currentInsuranceapplication?.regnumber == "" ? auth.currentInsuranceapplication?.objecttype : undefined,
                "aarsModell": auth.currentInsuranceapplication?.regnumber == undefined || auth.currentInsuranceapplication?.regnumber == "" ? auth.currentInsuranceapplication?.objectyear : undefined,
                "regaar": undefined, //TODO: Finne riktig verdi
                //"kilometerstand": isNaN(auth.currentInsuranceapplication?.mileage) ? 0 : Number(auth.currentInsuranceapplication?.mileage), // NOT IN USE IN FREMTIND
                "kundeEpost": auth.currentInsuranceapplication?.customerEmail || "", //AVVENTER OM RIKTIG VERDI
                "kundetelefonnummer": auth.currentInsuranceapplication?.customerPhone || "",  //AVVENTER OM RIKTIG VERDI
                "chassisNummer": auth.currentInsuranceapplication?.regnumber == undefined || auth.currentInsuranceapplication?.regnumber == "" ? auth.currentInsuranceapplication?.chassisnumber : undefined,
                "kundenummer": auth.currentInsuranceapplication?.kundeNummer, //TODO: Finne riktig verdi
                "bilforhandlerKundenummer": undefined, //TODO: Finne riktig verdi
                "panthaverKundenummer": auth.currentInsuranceapplication?.loanLeasing == "loan" ||
                    auth.currentInsuranceapplication?.loanLeasing == "leasing" ?
                        auth.currentInsuranceapplication?.loanLeasingCompany
                    : undefined,
                "dealerSequence": auth.selectedDealer?.Sequence,
                "sequence": undefined, //AVVENTER VERDI
                "userSequence": auth.user?.userdata?.Sequence,
                "forsikringssum": auth.currentInsuranceapplication?.purchaseSum?.toString() || "0",
                "medFinansiering": false,
                "bilforhandlerOrgnummer": auth.selectedDealer?.OrgNumber,
            }
        }).then((res: any) => {
            toast.dismiss();
            setSendingInsurance(false);
            if(res.data.kvittering != undefined){
                setReceipt(res.data.kvittering);
            }
            if(res.data?.ErrorResponse != undefined) {
                if(res.data.ErrorResponse.ErrorDetail?.length > 20) {
                    setErrorDetails(res.data.ErrorResponse);
                    toast.error("Noe gikk galt!")
                } else {
                    toast.error(`${res.data.ErrorResponse?.Code ?? ""} ${res.data.ErrorResponse?.Message ?? ""} ${res.data?.ErrorResponse?.ErrorDetail ?? ""}`);
                }
            } else {
                toast.success("Forsikringsforespørsel sendt!");
            }

            auth.setCurrentInsuranceapplication({
                ...auth.currentInsuranceapplication || new InsuranceapplicationModel(),
                avtaleNummer: res.data.avtaleNummer,
                avtaleVersjon: res.data.avtaleVersjon,
                kundeNummer: res.data.kundeNummer,
                messageId: res.data.messageId,
                CustomerSequence: res.data.CustomerSequence,
                DealerSequence: res.data.DealerSequence,
                ID: res.data.ID,
            });
        }).catch((err: any) => {
            setSendingInsurance(false);
            toast.dismiss();
            console.log(err);

            toast.error("Kunne ikke sende forsikringsforespørsel!");
            toast.error("Forsikringen kan ikke tegnes. Kontakt forhandlerstøtte tlf 21 02 80 22");
        });
    }
    
    useEffect(() => {
        setProblems(general.checkProblemsInsurance(auth.currentInsuranceapplication, auth.selectedAgreement));
    }, [auth.currentInsuranceapplication]);

    useEffect(() => {
        setErrorDetails(undefined);
    }, [auth.currentInsuranceapplication?.category, auth.currentInsuranceapplication?.regnumber, auth.currentInsuranceapplication?.objectmakeCode, auth.currentInsuranceapplication?.chassisnumber]);

    return (
        <div className={styles.insuranceapplication}>
            {auth.loading ?
                <div className={styles.loadingcontainer}>
                    <Spinner large={true} />
                </div>
            :
                <Grid className={styles.grid}>
                    <GridRow>
                        <GridCol lg="6" md="12" sm="12">
                            { auth.currentInsuranceapplication?.sequence != undefined || auth.currentInsuranceapplication?.ID != undefined ?
                                <ContentCard
                                    open={true}
                                    disableClick={true}
                                >
                                    <div className={styles.loaninfoarea}>
                                        <h2>Forsikring: {auth.currentInsuranceapplication?.produkt} - {auth.currentInsuranceapplication?.sequence || "Tilbud"}</h2>
                                        <p><span>Sist endret:</span><span>{auth.currentInsuranceapplication?.updateDate !== null ? moment(auth.currentInsuranceapplication?.updateDate).format("DD.MM.YYYY - HH:mm") : ""}</span></p>
                                        { auth.currentInsuranceapplication?.salgsAgentId != undefined ?
                                            <p><span>Selger:</span><span>{auth.currentInsuranceapplication?.salgsAgentId}</span></p>
                                        : null }
                                    </div>
                                    <div className={styles.loancaseinfoarea}>
                                        {auth.currentInsuranceapplication?.regnr || auth.currentInsuranceapplication?.chassisNummer} {
                                            auth.currentInsuranceapplication?.objectyear != undefined ||
                                            auth.currentInsuranceapplication?.objectmake != undefined ||
                                            auth.currentInsuranceapplication?.objectmodel != undefined ?
                                                <span>({auth.currentInsuranceapplication?.objectyear} {auth.currentInsuranceapplication?.objectmakeName} {auth.currentInsuranceapplication?.objectmodelName})</span>
                                            : null
                                        }
                                    </div>
                                    <div className={styles.loancasebuttons}>
                                        <TertiaryButton onClick={() => {
                                            let accepted = window.confirm("Er du sikker på at du vil forkaste alle endringer og starte en ny søknad?");
                                            if (accepted) {
                                                clearForm();
                                            }
                                        }}>Ny søknad</TertiaryButton>
                                    </div>
                                </ContentCard>
                            : null }
                            <ContentCard
                                open={true}
                                disableClick={true}
                                title="Velg objektkategori"
                                className="displayFlex"
                            >
                                <CategorySelector
                                    multicolumn={true}
                                    categories={[
                                        { value: "BIL", name: "Bil" },
                                        { value: "BOBIL", name: "Bobil" },
                                        { value: "CAMPINGVOGN", name: "Campingvogn" },
                                    ]}
                                    selectedCategory={auth.currentInsuranceapplication?.category}
                                    onChange={(category: string) => {
                                        auth.setCurrentInsuranceapplication({
                                            ...auth.currentInsuranceapplication || new InsuranceapplicationModel(),
                                            category: category
                                        });
                                    }}
                                ></CategorySelector>
                                { auth.currentInsuranceapplication?.category == "BOBIL" || auth.currentInsuranceapplication?.category == "CAMPINGVOGN" ?
                                    <div>
                                        &nbsp;
                                        <InputGroup
                                            label="Kjøpesum"
                                            tooltip="Kjøpesum framkommer som pris i kjøpekontrakt avtalt mellom selger og kjøper."
                                            //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                                        >
                                            {inputProps => (
                                                <>
                                                    <InputWithUsability
                                                        prefix="Kr"
                                                        tabIndex={1}
                                                        type="number"
                                                        name="purchasesum"
                                                        max={100000000}
                                                        useseparatorformatter
                                                        value={auth.currentInsuranceapplication?.purchaseSum || ""}
                                                        onChange={e => {
                                                            auth.setCurrentInsuranceapplication({
                                                                ...auth.currentInsuranceapplication || new InsuranceapplicationModel(),
                                                                purchaseSum: Number(e.target.value),
                                                            });
                                                        }}
                                                        {...inputProps}
                                                    />
                                                </>
                                            )}
                                        </InputGroup>
                                    </div>
                                : null }
                            </ContentCard>
                            <InsuranceObjectCard
                                open={true}
                                disabled={false}
                                currentInsuranceapplication={auth.currentInsuranceapplication}
                                setCurrentInsuranceapplication={auth.setCurrentInsuranceapplication}
                                selectedAgreement={auth.selectedAgreement}
                                selectedDealer={auth.selectedDealer}
                                foundVehicle={foundVehicle}
                                setFoundVehicle={setFoundVehicle}
                            ></InsuranceObjectCard>
                            <InsuranceCustomerCard
                                disabled={false}
                                open={true}
                                currentInsuranceapplication={auth.currentInsuranceapplication}
                                setCurrentInsuranceapplication={auth.setCurrentInsuranceapplication}
                            />
                            <InsurancePricingCard
                                disabled={false}
                                open={true}
                                currentInsuranceapplication={auth.currentInsuranceapplication}
                                setCurrentInsuranceapplication={(data) => {
                                    auth.setCurrentInsuranceapplication(data);
                                }}
                            />
                            <ContentCard
                                disabled={false}
                                open={true}
                                title="Innsending av søknad"
                            >
                                &nbsp;
                                <Grid className={styles.grid}>
                                    <GridRow>
                                        <GridCol sm={auth.currentInsuranceapplication?.category != "CAMPINGVOGN" ? "6" : "12"}>
                                            <SecondaryButton 
                                                disabled={sendingInsurance}
                                                onClick={() => {
                                                    let accepted = window.confirm("Er du sikker på at du vil nullstille søknaden og forkaste alle data?");
                                                    if (accepted) {
                                                        clearForm();
                                                    }
                                                }}
                                            >Nullstill</SecondaryButton>
                                        </GridCol>
                                        { auth.currentInsuranceapplication?.category != "CAMPINGVOGN" ?
                                            <GridCol sm="6">
                                                <SecondaryButton
                                                    disabled={sendingInsurance}
                                                    onClick={() => {
                                                        if (auth.currentInsuranceapplication?.avtaleNummer != undefined) {
                                                            sendInsuranceapplication("tilbud", true);
                                                        } else {
                                                            sendInsuranceapplication("tilbud", false);
                                                        }
                                                    }}
                                                >{auth.currentInsuranceapplication?.avtaleNummer == undefined ? "Lagre tilbud" : "Oppdater tilbud"}</SecondaryButton>
                                            </GridCol>
                                        : null }
                                    </GridRow>
                                </Grid>
                                &nbsp;
                                <ActionButton
                                    disabled={ sendingInsurance || problems.length > 0 }
                                    onClick={() => {
                                        if (problems.length == 0){
                                            if (auth.currentInsuranceapplication?.avtaleNummer != undefined) {
                                                sendInsuranceapplication(undefined, true);
                                            } else {
                                                sendInsuranceapplication(undefined, false);
                                            }
                                        }
                                    }}
                                >
                                    { "Send forsikringssøknad" }
                                </ActionButton>
                                { problems.length > 0 ?
                                    <div className={styles.problems}>
                                        { problems.length > 3 ?
                                            <Tooltip
                                                leaveDelay={200}
                                                enterTouchDelay={0}
                                                title={
                                                    problems.map((problem, key) =>
                                                        <p key={key}>{problem}</p>
                                                    )
                                                }
                                                placement="top"
                                                classes={{ tooltip: "problemstooltip" }}
                                            >
                                                <span className="material-symbols-outlined">warning</span>
                                            </Tooltip>
                                        : null}
                                        { problems.length < 4 ? problems.map((problem, key) =>
                                            <p key={key}>{problem}</p>
                                        ) : null }
                                    </div>
                                :
                                    <p>Send inn søknad på vegne av {auth.selectedDealer?.CompanyName}</p>
                                }
                                { receipt != undefined ?
                                    <div className={styles.errorDetails}>
                                        <h4 className="ffe-h4">Kvittering</h4>
                                        <p>{receipt}</p>
                                    </div>
                                : null}
                                {errorDetails != undefined ?
                                    <div className={styles.errorDetails}>
                                        <h4 className="ffe-h4">Noe gikk galt!</h4>
                                        {errorDetails?.Code != undefined || errorDetails?.Message != undefined ?
                                            <p className={styles.errorCode}>{errorDetails?.Code != undefined ? `Feilkode: ${errorDetails.Code}. `: ""}{errorDetails?.Message ?? ""}</p>
                                        : null}
                                        
                                        {errorDetails?.ErrorDetail != undefined ?
                                            <p>{errorDetails.ErrorDetail}</p>
                                        : null}
                                    </div>
                                : null }
                            </ContentCard>
                        </GridCol>
                    </GridRow>
                </Grid>
            }
        </div>
    );
}

export default Insuranceapplication;