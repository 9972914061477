import { Input, InputGroup, RadioButtonInputGroup, RadioSwitch } from "@sb1/ffe-form-react";
import { Tab, TabGroup } from "@sb1/ffe-tabs-react";
import React, { useEffect, useState } from "react";
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import InputWithUsability from "../../../../Shared/InputWithUsability/InputWithUsability";
import Select from 'react-select';
import { toast } from 'react-toastify';

import styles from './CaseObjectCard.module.scss';

import { Loanapplication as LoanapplicationModel} from "../../../../../model/Loanapplication";
import { useAxios } from "../../../../../AxiosHandler";
import objectFunctions from "../../../../../functions/objectFunctions";
import general from "../../../../../functions/general";
import DatePickerExtended from "../../../../Shared/DatePickerExtended/DatePickerExtended";
import { useLoanapplication } from "../../../../../LoanapplicationContext";
import { formatRegistrationNumber } from "../../../../../functions/dataSanitation";

function CaseObjectCard(props: CaseObjectCardProps) {
    const loanapplication = useLoanapplication();
    const axiosHandler = useAxios();
    const axios = axiosHandler.axios;

    const [objectMakes, setObjectMakes] = useState<any>([]);
    const [objectModels, setObjectModels] = useState<any>([]);
    const [objectVariants, setObjectVariants] = useState<any>([]);

    const [makesLoading, setMakesLoading] = useState<boolean>(false);
    const [modelsLoading, setModelsLoading] = useState<boolean>(false);
    const [variantsLoading, setVariantsLoading] = useState<boolean>(false);

    function fetchObjectMakesData(){
        if(props.objectCategorySequence == undefined) return;
        setMakesLoading(true);

        switch (general.getProduktType(props.objectCategorySequence, props.insuranceFremtindCode)) {
            case "BIL": {
                fetchObjectMakes();
                break;
            }
            case "BOBIL": {
                fetchCaravanObjectMakes();
                break;
            }
            case "CAMPINGVOGN": {
                fetchCampingTrailerObjectMakes();
                break;
            }
            case "OTHER":
            default: {
                fetchCustomObjectMakes();
                break;
            }
        }
    }

    function fetchObjectModelsData(year: number, make: string){
        if(props.objectCategorySequence == undefined) return;
        setModelsLoading(true);
        setObjectModels([]);
        
        switch (general.getProduktType(props.objectCategorySequence, props.insuranceFremtindCode)) {
            case "BIL": {
                fetchObjectModels(year, make);
                break;
            }
            case "BOBIL":
            case "CAMPINGVOGN": {
                setModelsLoading(false);
                break;
            }
            case "OTHER":
            default: {
                fetchCustomObjectModels(make);
                break;
            }
        }
    }

    function fetchObjectMakes() {
        axios.single({
            url: "/api/fremtind/kodeverk/bilmerke",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            let tempMakes = [];
            if (res.data != undefined) {
                tempMakes.push(...res.data.map((i:any) => {
                    return {
                        value: i.merkenr,
                        label: i.merkenavn
                    }
                }));
            }
            setObjectMakes(tempMakes);
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av mulige merker feilet! Vennligst prøv igjen");
        }).finally(() => {
            setMakesLoading(false);
        });
    }

    function fetchCaravanObjectMakes(){
        axios.single({
            url: "/api/fremtind/kodeverk/bobiler",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            let tempMakes = [];
            if (res.data != undefined) {
                // Reduce to only unique values based on .bilmerke
                let unique = res.data.filter((v: any, i: any, a: any) =>
                    a.findIndex((t: any) => (t.bilmerke === v.bilmerke)) === i
                );
                tempMakes.push(...unique.map((i:any) => {
                    const firstLetter = i.bilmerke.charAt(0);
                    const firstLetterCap = firstLetter.toUpperCase();
                    const remainingLetters = i.bilmerke.slice(1).toLowerCase();
                    const capitalizedWord = firstLetterCap + remainingLetters;
                    return {
                        value: i.bilmerkekode,
                        label: capitalizedWord,
                        variants: res.data.filter((v: any) => v.bilmerke == i.bilmerke)
                    }
                }));
            }

            //If make is set, but makeName is not, set makeName
            if(props.currentLoanapplication?.objectmake != undefined && 
                props.currentLoanapplication?.objectmake != "" && 
                (props.currentLoanapplication?.objectmakeName == undefined || props.currentLoanapplication?.objectmakeName == "")){
                const makeName = tempMakes.find((i: any) => i.variants.find((j: any) => j.bilmerkekode === props.currentLoanapplication?.objectmake))?.label;
                if(makeName) loanapplication.setObjectmakeName(makeName);
            }
            
            setObjectMakes(tempMakes);
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av mulige merker feilet! Vennligst prøv igjen");
        }).finally(() => {
            setMakesLoading(false);
        });
    }

    function fetchCampingTrailerObjectMakes(){
        setMakesLoading(true);
        setObjectMakes([]);
        setObjectModels([]);
        axios.single({
            url: "/api/fremtind/kodeverk/campingvogner",
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            let tempMakes = [];
            if (res.data != undefined) {
                tempMakes.push(...res.data.map((i:any) => {
                    return {
                        value: i.merkekode.trim(),
                        label: (i.merke.charAt(0) + i.merke.slice(1).toLowerCase())
                    }
                }));
            }
            setObjectMakes(tempMakes);
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av mulige merker feilet! Vennligst prøv igjen");
        }).finally(() => {
            setMakesLoading(false);
        });
    }

    function fetchCustomObjectMakes(){
        axios.single({
            url: "/api/customObjects/bilmerke/" + props.objectCategorySequence,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if (res.data != undefined) {
                setObjectMakes(res.data.map((i:any) => {
                    return {
                        value: i.MakeNameID,
                        label: i.MakeName
                    }
                }));
            }
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av mulige varianter feilet! Vennligst prøv igjen");
        }).finally(() => {
            setMakesLoading(false);
        });
    }
    
    function fetchObjectModels(year: number, make: string) {
        axios.single({
            url: "/api/fremtind/kodeverk/bilmodell/" + year + "/" + make,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if (res.data != undefined) {
                setObjectModels(res.data.map((i:any) => {
                    return {
                        value: i.modellnr,
                        label: i.modellnavn
                    }
                }));
            }
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av mulige modeller feilet! Vennligst prøv igjen");
        }).finally(() => {
            setModelsLoading(false);
        });
    }
    function fetchCustomObjectModels(MakeNameID: any) {
        axios.single({
            url: "/api/customObjects/bilmodell/" + MakeNameID,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if (res.data != undefined) {
                setObjectModels(res.data.map((i:any) => {
                    return {
                        value: i.ModelName,
                        label: i.ModelName
                    }
                }));
            }
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av mulige modeller feilet! Vennligst prøv igjen");
        }).finally(() => {
            setModelsLoading(false);
        });
    }
    
    function fetchObjectVariants(year: number, make: string, model: string) {
        setVariantsLoading(true);
        setObjectVariants([]);
        axios.single({
            url: "/api/fremtind/kodeverk/bilvariant/" + year + "/" + make + "/" + model,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if (res.data != undefined) {
                setObjectVariants(res.data.map((i:any) => {
                    return {
                        value: i.variantnr,
                        label: i.variantnavn
                    }
                }));
            }
        }).catch((err: any) => {
            console.log(err);
            toast.error("Innlasting av mulige varianter feilet! Vennligst prøv igjen");
        }).finally(() => {
            setVariantsLoading(false);
        });
    }

    async function searchRegistrationNumber(regnum: string) {
        return new Promise((resolve, reject) => {
            toast("Søker etter objekt ..", {
                autoClose: false
            });
            axios.single({
                url: "/api/caseobjects/" + formatRegistrationNumber(regnum, true),
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
                }
            }).then((res: any) => {
                let hasForstegangRegistrertDato = res.data.kjoretoydataListe[0].godkjenning?.forstegangsGodkjenning?.forstegangRegistrertDato != undefined;
                let year =
                    res.data.kjoretoydataListe[0].godkjenning?.forstegangsGodkjenning?.forstegangRegistrertDato?.split("-")[0] || 
                    res.data.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.kjoretoyklassifisering?.nasjonalGodkjenning?.nasjonaltGodkjenningsAr;

                let vin = res.data.kjoretoydataListe[0].kjoretoyId.understellsnummer;

                let modelYear = objectFunctions.getModelYear(year, vin, hasForstegangRegistrertDato);

                let objectyear = modelYear;
                let objectmake = res.data.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.generelt.merke[0].merke;
                let objectmodel = res.data.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.generelt.handelsbetegnelse[0];

                props.setFoundVehicle(objectmake + " " + objectmodel + " " + objectyear);
                loanapplication.setChassisnumber(vin);
                resolve(res.data);
                toast.dismiss();
            }).catch((err: any) => {
                props.setFoundVehicle("Fant ikke objekt")
                loanapplication.setObjectyear(undefined);
                loanapplication.setObjectmake(undefined);
                loanapplication.setObjectmakeCode(undefined);
                loanapplication.setObjectmodel(undefined);
                loanapplication.setObjecttype(undefined);
                loanapplication.setChassisnumber(undefined);
                toast.dismiss();
                console.log(err);
                toast.error("Søk på registreringsnummeret feilet! Vennligst prøv igjen");
                reject(err);
            });
        });
    }

    function renderObjectMakeField(){
        return(
            <InputGroup
                label="Merke"
                tooltip="Fritekst søk etter merke på finansieringsobjektet. Krever utfylling av årsmodell først."
                //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
            >
                <Select
                    noOptionsMessage={() => "Fant ingen merker for valgt år"}
                    placeholder="Søk ..."
                    loadingMessage={() => "Laster inn merker ..."}
                    isLoading={makesLoading}
                    isDisabled={
                        props.currentLoanapplication?.objectyear == undefined ||
                        props.currentLoanapplication?.objectyear < 1900 ||
                        props.currentLoanapplication?.objectyear > (new Date().getFullYear() + 1)
                    }
                    className="basic-singleselect"
                    classNamePrefix="select"
                    value={
                        [
                            {
                                value: props.currentLoanapplication?.objectmake,
                                label: 
                                    objectMakes.filter(
                                        (i: any) => i.value != "other"
                                    ).find(
                                        (i: any) => i.value == props.currentLoanapplication?.objectmake
                                    )?.label ?? props.currentLoanapplication?.objectmakeName,
                            }
                        ]
                    }
                    defaultValue={
                        [
                            {
                                value: props.currentLoanapplication?.objectmake,
                                label: 
                                    objectMakes.filter(
                                        (i: any) => i.value != "other"
                                    ).find(
                                        (i: any) => i.value == props.currentLoanapplication?.objectmake
                                    )?.label ?? props.currentLoanapplication?.objectmakeName,
                            }
                        ]
                    }
                    isClearable={true}
                    isSearchable={true}
                    options={objectMakes}
                    onInputChange={(e) => {
                        if(e == "") return;
                        setObjectMakes([
                            ...objectMakes.filter((i: any) => i.value != "other"),
                            !(objectMakes.filter((i: any) => i.value != "other").some((i: any) => i.label == e)) ? 
                                {
                                    value: "other",
                                    label: e,
                                }
                            : null
                        ].filter((i: any) => i != null));
                        if (objectMakes.filter((i: any) => i.value != "other").some((i: any) => i.label == e)) {
                            loanapplication.setObjectmake(objectMakes.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.value);
                            loanapplication.setObjectmakeCode(objectMakes.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.value);
                            loanapplication.setObjectmakeName(objectMakes.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.label);
                            loanapplication.setObjectmodel(undefined);
                            loanapplication.setObjectmodelName(undefined);

                            props.objectmakeChanged?.(objectMakes.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.value);
                            props.objectmodelChanged?.(undefined);
                            props.objectvariantChanged?.(undefined);
                        }
                    }}
                    onChange={(e) => {
                        loanapplication.setObjectmake((e as any)?.value);
                        loanapplication.setObjectmakeCode((e as any)?.value);
                        loanapplication.setObjectmakeName((e as any)?.label);
                        loanapplication.setObjectmodel(undefined);
                        loanapplication.setObjectmodelName(undefined);

                        props.objectmakeChanged?.((e as any)?.value);
                        props.objectmodelChanged?.(undefined);
                        props.objectvariantChanged?.(undefined);
                    }}
                />
            </InputGroup>
        )
    }

    function renderObjectModelField(){
        return(
            <InputGroup
                label="Modell"
                tooltip="Fritekst søk etter modell på finansieringsobjektet. Krever utfylling av merke først."
                //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
            >
                <Select
                    noOptionsMessage={() => "Fant ingen modeller for dette merket"}
                    placeholder="Søk ..."
                    loadingMessage={() => "Laster inn modeller ..."}
                    isLoading={modelsLoading}
                    className="basic-singleselect"
                    classNamePrefix="select"
                    value={
                        [
                            {
                                value: props.currentLoanapplication?.objectmodel,
                                label: objectModels.filter(
                                    (i: any) => i.value != "other"
                                ).find(
                                    (i: any) => i.value == props.currentLoanapplication?.objectmodel
                                )?.label || props.currentLoanapplication?.objectmodelName,
                            }
                        ]
                    }
                    defaultValue={
                        [
                            {
                                value: props.currentLoanapplication?.objectmodel,
                                label: objectModels.filter(
                                    (i: any) => i.value != "other"
                                ).find(
                                    (i: any) => i.value == props.currentLoanapplication?.objectmodel
                                )?.label || props.currentLoanapplication?.objectmodelName,
                            }
                        ]
                    }
                    isClearable={true}
                    isSearchable={true}
                    options={objectModels}
                    onInputChange={(e) => {
                        if(e == "") return;
                        setObjectModels([
                            ...objectModels.filter((i: any) => i.value != "other"),
                            !(objectModels.filter((i: any) => i.value != "other").some((i: any) => i.label == e)) ? 
                                {
                                    value: "other",
                                    label: e,
                                }
                            : null
                        ].filter((i: any) => i != null));
                        if (objectModels.filter((i: any) => i.value != "other").some((i: any) => i.label == e)) {
                            loanapplication.setObjectmodel(objectModels.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.value);
                            loanapplication.setObjectmodelName(objectModels.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.label);

                            props.objectmodelChanged?.(objectModels.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.value);
                            props.objectvariantChanged?.(undefined);
                        }
                    }}
                    onChange={(e) => {
                        loanapplication.setObjectmodel((e as any)?.value);
                        loanapplication.setObjectmodelName((e as any)?.label);

                        props.objectmodelChanged?.((e as any)?.value);
                        props.objectvariantChanged?.(undefined);
                    }}
                />
            </InputGroup>
        )
    }

    function renderObjectVariantField(){
        return(
            <InputGroup
                label="Variant"
                tooltip="Fritekst søk etter variant på finansieringsobjektet. Krever utfylling av modell først."
                //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
            >
                <Select
                    noOptionsMessage={() => "Fant ingen varianter for valgt modell"}
                    placeholder="Søk ..."
                    loadingMessage={() => "Laster inn varianter ..."}
                    isLoading={variantsLoading}
                    className="basic-singleselect"
                    classNamePrefix="select"
                    value={
                        [
                            {
                                value: props.currentLoanapplication?.objecttype,
                                label: props.currentLoanapplication?.objecttypeName,
                            }
                        ]
                    }
                    defaultValue={
                        [
                            {
                                value: props.currentLoanapplication?.objecttype,
                                label: props.currentLoanapplication?.objecttypeName,
                            }
                        ]
                    }
                    isClearable={true}
                    isSearchable={true}
                    options={objectVariants}
                    onInputChange={(e) => {
                        if(e == "") return;
                        setObjectVariants([
                            ...objectVariants.filter((i: any) => i.value != "other"),
                            !(objectVariants.filter((i: any) => i.value != "other").some((i: any) => i.label == e)) ? 
                                {
                                    value: "other",
                                    label: e,
                                }
                            : null
                        ].filter((i: any) => i != null));
                        if (objectVariants.filter((i: any) => i.value != "other").some((i: any) => i.label == e)) {
                            loanapplication.setObjecttype(objectVariants.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.value);
                            loanapplication.setObjecttypeName(objectVariants.filter((i: any) => i.value != "other").find((i: any) => i.label == e)?.label);
                        }
                    }}
                    onChange={(e) => {
                        loanapplication.setObjecttype((e as any)?.value);
                        loanapplication.setObjecttypeName((e as any)?.label);
                    }}
                />
            </InputGroup>
        )
    }

    function renderObjectValuesField(){
        return(
            <InputGroup
                label="Verdi"
                tooltip="Valg av verdien på finansieringsobjektet. Krever utfylling av merke først."
            >
                <Select
                    noOptionsMessage={() => "Fant ingen verdier for valgt merke"}
                    placeholder="Søk ..."
                    loadingMessage={() => "Laster inn verdier ..."}
                    isLoading={variantsLoading}
                    className="basic-singleselect"
                    classNamePrefix="select"
                    value={[
                        {
                            value: props.currentLoanapplication?.objectmakeCode,
                            label: objectMakes.find((i: any) => 
                                i?.label?.toUpperCase() === props.currentLoanapplication?.objectmakeName?.toUpperCase()
                            )?.variants?.find((i: any) => 
                                i?.bilmerkekode?.toUpperCase() === props.currentLoanapplication?.objectmakeCode?.toUpperCase()
                            )?.beskrivelse
                        }
                    ]}
                    defaultValue={[
                        {
                            value: props.currentLoanapplication?.objectmakeCode,
                            label: objectMakes.find((i: any) => 
                                i?.label?.toUpperCase() === props.currentLoanapplication?.objectmakeName?.toUpperCase()
                            )?.variants?.find((i: any) => 
                                i?.bilmerkekode?.toUpperCase() === props.currentLoanapplication?.objectmakeCode?.toUpperCase()
                            )?.beskrivelse
                        }
                    ]}
                    isClearable={true}
                    isSearchable={false}
                    options={objectMakes.filter(
                        (i: any) => i.value != "other"
                    ).find(
                        (i: any) => i.label?.toUpperCase() == props.currentLoanapplication?.objectmakeName?.toUpperCase()
                    )?.variants?.map((i: any) => {
                        return {
                            value: i.bilmerkekode,
                            label: i.beskrivelse
                        }
                    })}
                    onChange={(e) => {
                        loanapplication.setObjectmake((e as any)?.value);
                        loanapplication.setObjectmakeCode((e as any)?.value);
                        props.objectmakeChanged?.((e as any)?.value);
                    }}
                />
            </InputGroup>
        )
    }

    function renderObjectMakeModelVariantFields() {
        switch (general.getProduktType(props.objectCategorySequence, props.insuranceFremtindCode)) {
            //BIL
            case "BIL": {
                return(
                    <> 
                        {renderObjectMakeField()}
                        {renderObjectModelField()}
                        {renderObjectVariantField()}
                    </>
                );
            }
            //BOBIL
            case "BOBIL": {
                return(
                    <>
                        {renderObjectMakeField()}
                        {renderObjectValuesField()}
                    </>
                );
            }
            //ANNET
            case "CAMPINGVOGN":
            case "OTHER":
            default: {
                return(
                    <> 
                        {renderObjectMakeField()}
                        {renderObjectModelField()}
                    </>
                );
            }
        }
    }

    useEffect(() => {
        fetchObjectMakesData();
        if (
            props.selectedAgreement != undefined &&
            props.selectedDealer != undefined &&
            props.currentLoanapplication != undefined &&
            !general.showArea("baatRegnummer", props.selectedAgreement, props.selectedDealer, props.currentLoanapplication) &&
            props.currentLoanapplication?.regnumber != undefined &&
            props.currentLoanapplication?.regnumber != ""
        ) {
            searchRegistrationNumber(props.currentLoanapplication?.regnumber);
        }
    }, [
        props.selectedAgreement,
        props.selectedDealer
    ]);

    useEffect(() => {
        if (
            props.currentLoanapplication?.objectmake != undefined && props.currentLoanapplication?.objectmake != "" &&
            props.currentLoanapplication?.objectyear != undefined
        ) {
            fetchObjectModelsData(
                props.currentLoanapplication?.objectyear,
                props.currentLoanapplication?.objectmake
            );
            /* loanapplication.setObjectmodel(""); */
            loanapplication.setObjecttype(""); 
        }
    }, [
        props.currentLoanapplication?.objectmake,
        props.currentLoanapplication?.objectyear,
        props.objectCategorySequence
    ]);

    useEffect(() => {
        if (
            props.currentLoanapplication?.objectmake != undefined && props.currentLoanapplication?.objectmake != "" && 
            props.currentLoanapplication?.objectyear != undefined &&
            props.currentLoanapplication?.objectmodel != undefined && props.currentLoanapplication?.objectmodel != ""
        ) {
            fetchObjectVariants(
                props.currentLoanapplication?.objectyear,
                props.currentLoanapplication?.objectmake,
                props.currentLoanapplication?.objectmodel
            );
            loanapplication.setObjecttype("");
        }
    }, [
        props.currentLoanapplication?.objectmake,
        props.currentLoanapplication?.objectyear,
        props.currentLoanapplication?.objectmodel,
        props.objectCategorySequence
    ]);

    useEffect(() => {
        fetchObjectMakesData();
    }, [
        props.objectCategorySequence, 
        props.insuranceFremtindCode,
    ]);
    
    return(
        <ContentCard disabled={props.disabled} open={props.open} title="Opplysninger om objekt">
            { general.showArea("tillatFinansieringsbevis", props.selectedAgreement, props.selectedDealer, props.currentLoanapplication) ?
                <TabGroup className={styles.carinfotoggle}>
                    <Tab
                        selected={props.currentLoanapplication?.exactCar != undefined ? props.currentLoanapplication?.exactCar : false}
                        onClick={() => {
                            loanapplication.setExactCar(true);
                        }}
                        aria-controls={"exact"}
                    >
                        Konkret objekt
                    </Tab>
                    <Tab
                        selected={props.currentLoanapplication?.exactCar != undefined ? !props.currentLoanapplication?.exactCar : false}
                        onClick={() => {
                            loanapplication.setExactCar(false);
                        }}
                        disabled={general.showArea("tillatFinansieringsbevis", props.selectedAgreement, props.selectedDealer, props.currentLoanapplication) ? false : true}
                        aria-controls={"proofofequity"}
                    >
                        Finansieringsbevis
                    </Tab>
                </TabGroup>
            : null }
            { props.currentLoanapplication?.exactCar === true || (!general.showArea("tillatFinansieringsbevis", props.selectedAgreement, props.selectedDealer, props.currentLoanapplication)) ?
                <>
                    { general.showArea("visRegnrSok", props.selectedAgreement, props.selectedDealer, props.currentLoanapplication) ||
                    props.currentLoanapplication?.exactCar === true ?
                        <RadioButtonInputGroup
                            label="Vet du registreringsnummeret på objektet?"
                            //tooltip=""
                            name="regnrknown"
                            onChange={e => {
                                loanapplication.setRegnrknown(e.target.value == "true");
                            }}
                            selectedValue={props.currentLoanapplication?.regnrknown != undefined ? props.currentLoanapplication?.regnrknown.toString() : ""}
                        >
                            {inputProps => (
                                <RadioSwitch
                                    leftLabel="Ja"
                                    leftValue="true"
                                    rightLabel="Nei"
                                    rightValue="false"
                                    {...inputProps}
                                />
                            )}
                        </RadioButtonInputGroup>
                    : null }
                    &nbsp;
                    { props.currentLoanapplication?.regnrknown != undefined && props.currentLoanapplication?.regnrknown ?
                        <>
                            <InputGroup
                                label="Registreringsnummer"
                                tooltip="Registreringsnummer på objektet som skal finansieres. Eks: AB12345"
                                //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                            >
                                {inputProps => (
                                    <Input
                                        type="text"
                                        name="regnumber"
                                        value={props.currentLoanapplication?.regnumber || ""}
                                        onChange={e => {
                                            loanapplication.setRegnumber(e.target.value);

                                            props.regnumberChanged?.(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                            if (e.target.value.length > 1) {
                                                searchRegistrationNumber(e.target.value).then((res: any) => {
                                                    let hasForstegangRegistrertDato = res.kjoretoydataListe[0].godkjenning?.forstegangsGodkjenning?.forstegangRegistrertDato != undefined;
                                                    
                                                    let year =
                                                        res.kjoretoydataListe[0].godkjenning?.forstegangsGodkjenning?.forstegangRegistrertDato?.split("-")[0] || 
                                                        res.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.kjoretoyklassifisering?.nasjonalGodkjenning?.nasjonaltGodkjenningsAr;
                                                    
                                                    let vin = res.kjoretoydataListe[0].kjoretoyId.understellsnummer;

                                                    let modelYear = objectFunctions.getModelYear(year, vin, hasForstegangRegistrertDato);
                                                    
                                                    let tempObjectmakeCode = objectMakes?.find((make: any) => 
                                                        make.label == general.capitalize(res.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.generelt.merke[0].merke)
                                                    )?.value ?? undefined;
                                                    
                                                    if(general.getProduktType(props.objectCategorySequence, props.insuranceFremtindCode) == "BOBIL") objectMakes?.map((make: any) => {
                                                        if(make.label.toUpperCase() == res.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.generelt.merke[0].merke.toUpperCase()){
                                                            tempObjectmakeCode = make.value;
                                                            /* make.variants.map((variant: any) => {
                                                                if(
                                                                    (props.currentLoanapplication?.purchaseSum ?? 0) >= variant.minverdi && 
                                                                    (props.currentLoanapplication?.purchaseSum ?? 0) <= variant.maxverdi
                                                                ) {
                                                                    tempObjectmakeCode = variant.bilmerkekode;
                                                                }
                                                            }); */
                                                        }
                                                    });

                                                    loanapplication.setRegnumber(res.kjoretoydataListe[0].kjoretoyId?.kjennemerke);
                                                    props.regnumberChanged?.(res.kjoretoydataListe[0].kjoretoyId?.kjennemerke);
                                                    loanapplication.setObjectyear(modelYear);
                                                    loanapplication.setObjectmakeName(res.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.generelt.merke[0].merke);
                                                    loanapplication.setObjectmakeCode(tempObjectmakeCode);
                                                    loanapplication.setObjectmodelName(res.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.generelt.handelsbetegnelse[0]);
                                                    loanapplication.setObjecttypeName(res.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.generelt.typebetegnelse);
                                                }).catch((err: any) => {
                                                    console.log(err);
                                                });
                                            }
                                            else {
                                                props.setFoundVehicle(""); 
                                                loanapplication.setObjectyear(undefined);
                                                loanapplication.setObjectmake(undefined);
                                                loanapplication.setObjectmakeCode(undefined);
                                                loanapplication.setObjectmodel(undefined);
                                                loanapplication.setObjecttype(undefined);
                                            }
                                        }}
                                        {...inputProps}
                                    />
                                )}
                            </InputGroup>
                            &nbsp;
                            {props.foundVehicle != "" ?
                                <>
                                    <p>{props.foundVehicle}</p>
                                </>
                            : null}
                            {general.getProduktType(props.objectCategorySequence, props.insuranceFremtindCode) == "BOBIL" ? renderObjectValuesField() : null}
                            {general.showArea("kmStand", props.selectedAgreement, props.selectedDealer, props.currentLoanapplication) ?
                                <InputGroup
                                    label="Kilometerstand"
                                    tooltip="Antall kilometer som objektet har kjørt"
                                    //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                                >
                                    {inputProps => (
                                        <InputWithUsability
                                            useseparatorformatter
                                            prefix="Km"
                                            type="number"
                                            value={props.currentLoanapplication?.mileage || ""}
                                            name="mileage"
                                            min={0}
                                            onChange={e => {
                                                loanapplication.setMileage(e.target.value as any);
                                            }}
                                            {...inputProps}
                                        />
                                    )}
                                </InputGroup>   
                            : null}
                        </>
                    : null }
                    { loanapplication?.regnrknown != undefined && !props.currentLoanapplication?.regnrknown ?
                        <>
                            <InputGroup
                                label="Årsmodell"
                                tooltip="Årsmodell på finansieringsobjektet. Eks: 2019"
                                fieldMessage={
                                        (props.currentLoanapplication?.objectyear != undefined && props.currentLoanapplication?.objectyear < 1800) || 
                                        (props.currentLoanapplication?.objectyear != undefined && props.currentLoanapplication?.objectyear > new Date().getFullYear() + 5)
                                    ?
                                        "Ugyldig årsmodell"
                                    : null
                                }
                            >
                                {inputProps => (
                                    <Input
                                        type="number"
                                        name="objectyear"
                                        min={1800}
                                        max={new Date().getFullYear() + 5}
                                        value={props.currentLoanapplication?.objectyear || ""}
                                        onChange={e => {
                                            loanapplication.setObjectyear(e.target.value as any);

                                            props.objectyearChanged?.(e.target.value);
                                            props.objectmakeChanged?.(undefined);
                                            props.objectmodelChanged?.(undefined);
                                            props.objectvariantChanged?.(undefined);
                                        }}
                                        {...inputProps}
                                    />
                                )}
                            </InputGroup>
                            {renderObjectMakeModelVariantFields()}
                            { general.showArea("baatRegnummer", props.selectedAgreement, props.selectedDealer, props.currentLoanapplication) ?
                                <InputGroup
                                    label="Serienr/regnr"
                                    tooltip="Registreringsnummer eller serienummer på objektet som skal finansieres."
                                    //fieldMessage={showErrors ? 'Ugyldig telefonnummer' : null}
                                >
                                    {inputProps => (
                                        <Input
                                            type="text"
                                            name="regnumber"
                                            value={props.currentLoanapplication?.regnumber || ""}
                                            onBlur={e => {
                                                let regNumber = formatRegistrationNumber(e.target.value);
                                                loanapplication.setRegnumber(regNumber);
                                                props.regnumberChanged?.(regNumber);
                                            }}
                                            onChange={e => {
                                                loanapplication.setRegnumber(e.target.value);

                                                props.regnumberChanged?.(e.target.value);
                                            }}
                                            {...inputProps}
                                        />
                                    )}
                                </InputGroup>
                            : null }
                            { props.objectCategorySequence == 7 || !general.showArea("visRegnrSok", props.selectedAgreement, props.selectedDealer, props.currentLoanapplication) ?
                                <InputGroup
                                    label="Chassisnummer (kreves for forsikring)"
                                    tooltip="Chassisnummer på finansieringsobjektet. 9 tegn. Kreves for bestilling av forsikring uten regnummer."
                                    fieldMessage={
                                        props.currentLoanapplication?.chassisnumber != undefined &&
                                        props.currentLoanapplication?.chassisnumber != "" &&
                                        props.currentLoanapplication?.chassisnumber.length != 9
                                            ? "Ugyldig chassisnummer. Må være 9 tegn."
                                            : null
                                    }
                                >
                                    {inputProps => (
                                        <Input
                                            type="text"
                                            name="chassisnumber"
                                            value={props.currentLoanapplication?.chassisnumber || ""}
                                            onChange={e => {
                                                loanapplication.setChassisnumber(e.target.value as any);
                                            }}
                                            {...inputProps}
                                        />
                                    )}
                                </InputGroup>
                            : null}
                        </>
                    : null }
                </>
            : 
            props.currentLoanapplication?.exactCar === false ?
                    <InputGroup
                        label="Eldste årsmodell"
                        tooltip="Eldste årsmodell som finansieringsbeviset skal være gyldig for. Eks: 2019"
                        fieldMessage={
                                (props.currentLoanapplication?.objectyear != undefined && props.currentLoanapplication?.objectyear < 1800) || 
                                (props.currentLoanapplication?.objectyear != undefined && props.currentLoanapplication?.objectyear > new Date().getFullYear() + 5)
                            ?
                                "Ugyldig årsmodell"
                            : null
                        }
                    >
                        {inputProps => (
                            <Input
                                type="number"
                                name="objectyear"
                                value={props.currentLoanapplication?.objectyear || ""}
                                min={1800}
                                max={new Date().getFullYear() + 5}
                                onChange={e => {
                                    loanapplication.setObjectyear(e.target.value as any);
                                }}
                                {...inputProps}
                            />
                        )}
                    </InputGroup>
                : null
            }
            {general.showArea("antallKilometer", props.selectedAgreement, props.selectedDealer, props.currentLoanapplication) &&
            props.currentLoanapplication?.remainingValue != null && props.currentLoanapplication?.remainingValue != undefined && props.currentLoanapplication?.remainingValue != 0 ?
                <InputGroup
                    label="Antall kilometer (i avtaleperioden)"
                    // tooltip="Antall kilometer som du har til rådighet per måned."
                >
                    {inputProps => (
                        <InputWithUsability
                            useseparatorformatter
                            prefix="Km"
                            type="number"
                            value={props.currentLoanapplication?.useableKm || ""}
                            name="useablekm"
                            step={1000}
                            min={0}
                            onChange={e => {
                                loanapplication.setUseableKm(e.target.value as any);
                            }}
                            {...inputProps}
                        />
                    )}
                </InputGroup>   
            : null}
            {general.showArea("prisOverkjortKmEksMva", props.selectedAgreement, props.selectedDealer, props.currentLoanapplication) && 
            props.currentLoanapplication?.remainingValue != null && props.currentLoanapplication?.remainingValue != undefined && props.currentLoanapplication?.remainingValue != 0 ?
                <InputGroup
                    label="Pris per overkjørte KM (eks mva)"
                >
                    {inputProps => (
                        <InputWithUsability
                            useseparatorformatter
                            prefix="Kr"
                            type="number"
                            value={props.currentLoanapplication?.priceExtraKmExclVAT || ""}
                            name="priceextrakmexclvat"
                            step={100}
                            min={0}
                            onChange={e => {
                                loanapplication.setPriceExtraKmExclVAT(e.target.value as any);
                            }}
                            {...inputProps}
                        />
                    )}
                </InputGroup>   
            : null}
            {general.showArea("prisOverkjortKmInklMva", props.selectedAgreement, props.selectedDealer, props.currentLoanapplication) &&
            props.currentLoanapplication?.remainingValue != null && props.currentLoanapplication?.remainingValue != undefined && props.currentLoanapplication?.remainingValue != 0 ?
                <InputGroup
                    label="Pris per overkjørte KM (inkl mva)"
                >
                    {inputProps => (
                        <InputWithUsability
                            useseparatorformatter
                            prefix="Kr"
                            type="number"
                            value={props.currentLoanapplication?.priceExtraKmInclVAT || ""}
                            name="priceextrakminclvat"
                            step={100}
                            min={0}
                            onChange={e => {
                                loanapplication.setPriceExtraKmInclVAT(e.target.value as any);
                            }}
                            {...inputProps}
                        />
                    )}
                </InputGroup>
            : null}
            {/* {general.showArea("antattLeveringsdato", props.selectedAgreement, props.selectedDealer, props.currentLoanapplication) ?
                <DatePickerExtended
                    //inputProps={{ id: 'deliverydate' }}
                    label="Antatt leveringsdato"
                    language="nb"
                    maxDate="31.12.2033"
                    minDate="01.01.2023"
                    onChange={(e: any) => {
                        loanapplication.setDeliveryDate(e);
                    }}
                    value={props.currentLoanapplication?.deliveryDate || ""}
                />
            : null} */}
        </ContentCard>
    );
}

interface CaseObjectCardProps {
    disabled: boolean;
    open: boolean;
    currentLoanapplication: LoanapplicationModel;
    selectedAgreement: any;
    selectedDealer: any;
    foundVehicle: string;
    setFoundVehicle: (foundVehicle: string) => void;
    regnumberChanged?: (regnumber: string) => void;
    objectyearChanged?: (objectyear: string | undefined) => void;
    objectmakeChanged?: (objectmake: string | undefined) => void;
    objectmodelChanged?: (objectmodel: string | undefined) => void;
    objectvariantChanged?: (objectvariant: string | undefined) => void;
    //uniqueCategories?: any[];
    objectCategorySequence?: number | undefined;
    insuranceFremtindCode?: string | undefined;
}

export default CaseObjectCard;